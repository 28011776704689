<template>
  <v-row class="mt-7" no-gutters style="min-width:570px;width:30%">
    <v-col class="tagFilterContainer mr-2" md="6">
      <associatedOption
        :fieldAttributes="{
          name: 'by Category:',
          associatedOption: result.additional.domainTestGroups,
        }"
        type="tagCombo"
        :value="groupFilter"
        @input="updateFilter(...arguments)"
        :fieldAttrInput="{
          multiple: true,
          chips: true,
          class: 'tagContainerAdjust',
        }"
      >
      <template v-slot:selection="slotProps">
          <v-chip
            label
            x-small
            close
            @click.stop="updateOperation(slotProps.item, slotProps.index)"
            @click:close="removeValue(slotProps.item)"
          >
            <span :class="getColor(slotProps.item)">{{
              slotProps.item.text
            }}</span>
          </v-chip>
        </template>
      </associatedOption>
    </v-col>
    <v-col class="d-flex" style="max-width:280px">
      <primaryButton
        class="mr-2"
        label="Filter"
        icon="mdi-filter"
        @click="applyFilter(groupFilter)"
        type="button"
        color="primary"
          shrink="mdAndDown"
      ></primaryButton>
      <primaryButton
        label="Show All"
        icon="mdi-menu"
        @click="applyFilter([])"
        type="button"
        color="primary"
          shrink="mdAndDown"
      ></primaryButton>
    </v-col>
  </v-row>
</template>
<script>
import associatedOption from "@/commonComponents/associatedOption.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
export default {
  data() {
    return {
      operations: {
        0: { title: "OR", color: "blue" },
        1: { title: "AND", color: "green" },
        2: { title: "NOT", color: "red" },
      },
    };
  },
  components: { associatedOption, primaryButton },
  props: ["result", "currentTable"],
  computed: {
    groupFilter: {
      get() {
        let groupFilter = [];
        if (
          typeof this.currentTable.groupFilter != "undefined" &&
          Array.isArray(this.currentTable.groupFilter)
        ) {
          this.currentTable.groupFilter.forEach((element) => {
            if (
              typeof element["data-operation"] == "undefined" &&
              element == "object"
            ) {
              element["data-operation"] = 1;
            }
            groupFilter.push({ text: element.value, ...element });
          });
        }
        return groupFilter;
      },
      set(newFilter) {
        let filters = [];
        if (Array.isArray(newFilter)) {
          newFilter.forEach((value) => {
            if (typeof value == "object") {
              //console.log(value);
              filters.push(value);
            }
          });
        }
        //console.log(filters);
        //  let result = this.result;
        this.$set(this.currentTable, "groupFilter", filters);
        //this.$store.commit("resultChanged", result);
      },
    },
  },
  methods: {
    removeValue(item) {
      //console.log(this.groupFilter, item.value);
      //this.groupFilter.splice(this.groupFilter.indexOf(item.value), 1);
      let clickedIndex = this.groupFilter.findIndex(x => x.value ===item.value);
      this.groupFilter.splice(clickedIndex, 1);
      this.$set(this.currentTable, "groupFilter", this.groupFilter);
      // let result = this.result;
      // this.$set(result.additional, "groupFilter", this.groupFilter);
      // this.$store.commit("resultChanged", result);
      //let result = this.result;
      //this.$set(result.table, "groupFilter", this.groupFilter);
     // this.$store.commit("resultChanged", result);
    },
    updateFilter(value, text, info) {
      this.$set(this, "groupFilter", info);
      let result = this.result;
      this.$set(result.table, "groupFilter", this.groupFilter);
     // this.$store.commit("resultChanged", result);
      //console.log(this.groupFilter);
    },

    updateOperation(item, index) {
      //console.log(item);
      let currentOperationIndex = 1;
      if (typeof item["data-operation"] != "undefined") {
        currentOperationIndex = item["data-operation"];
      }
      currentOperationIndex++;
      if (currentOperationIndex >= Object.keys(this.operations).length) {
        currentOperationIndex = 0;
      }
      this.$set(item, "data-operation", currentOperationIndex);
      this.$set(this.groupFilter, index, item);
      // this.$parent.$emit("input");
      //console.log(this.groupFilter);
      // setOperationProperties($tag,currentOperationIndex);
    },
    getColor(item) {
      //console.log(item);
      let currentOperationIndex = 0;
      if (typeof item["data-operation"] != "undefined") {
        currentOperationIndex = item["data-operation"];
      }
      return this.operations[currentOperationIndex].color + "--text";
    },
    applyFilter(filters) {

      //let path = this.currentTable.tableUrl;
      let path = "serve.php?f=testing&f2=configureAndRunTests";
      let options = {
        function: "registerTestGroupMulti",
        testGroupValue: filters,
        requestType: "ajax",
      };
      let caller = this;
      this.frameworkAxiosRequest({
        method: 'post',
        url: path,
        data: options,
      })
          .then(function (response) {
          //handle success
          caller.$store.commit("tableChanged", response.data.result.table);
          caller.$set(caller.currentTable, "data", response.data.result.table.data);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    },
  },
};
</script>
<style>
.tagContainerAdjust .v-label {
  background-color: #ffffff;
  z-index: 1;
}
.tagContainerAdjust .v-label.theme--dark {
   color: #000000;
}

.tagContainerAdjust .v-select__selections {
  overflow-y: hidden;
  height: 40px;
}
.tagContainerAdjust .v-input__slot {
  height: 40px;
}

.tagContainerAdjust .v-select__selections .v-chip__content .v-chip__close {
  font-size: 12px !important;
  /* position: relative;
  top: 0px;
  left: 6px; */
}

.tagContainerAdjust .v-select__selections .v-chip {
  margin: 1px;
}
</style>