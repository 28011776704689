var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"mt-7",staticStyle:{"min-width":"570px","width":"30%"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"tagFilterContainer mr-2",attrs:{"md":"6"}},[_c('associatedOption',{attrs:{"fieldAttributes":{
        name: 'by Category:',
        associatedOption: _vm.result.additional.domainTestGroups,
      },"type":"tagCombo","value":_vm.groupFilter,"fieldAttrInput":{
        multiple: true,
        chips: true,
        class: 'tagContainerAdjust',
      }},on:{"input":function($event){return _vm.updateFilter(...arguments)}},scopedSlots:_vm._u([{key:"selection",fn:function(slotProps){return [_c('v-chip',{attrs:{"label":"","x-small":"","close":""},on:{"click":function($event){$event.stopPropagation();return _vm.updateOperation(slotProps.item, slotProps.index)},"click:close":function($event){return _vm.removeValue(slotProps.item)}}},[_c('span',{class:_vm.getColor(slotProps.item)},[_vm._v(_vm._s(slotProps.item.text))])])]}}])})],1),_c('v-col',{staticClass:"d-flex",staticStyle:{"max-width":"280px"}},[_c('primaryButton',{staticClass:"mr-2",attrs:{"label":"Filter","icon":"mdi-filter","type":"button","color":"primary","shrink":"mdAndDown"},on:{"click":function($event){return _vm.applyFilter(_vm.groupFilter)}}}),_c('primaryButton',{attrs:{"label":"Show All","icon":"mdi-menu","type":"button","color":"primary","shrink":"mdAndDown"},on:{"click":function($event){return _vm.applyFilter([])}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }